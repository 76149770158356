import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import axios from "axios";

import { AppContext } from "../configs/AppContext";

import DefaultInput from "../components/DefaultInput";
import DefaultInputPassword from "../components/DefaultInputPassword";
import { API } from "../configs/API";
import cleanObject from "../helpers/cleanObject";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { Modal, Spinner } from "react-bootstrap";

const ModalTracking = () => {
  const [state, dispatch] = useContext(AppContext);

  const data = {
    error: 0,
    description: "OK",
    data: {
      status: "delivered",
      tracking: {
        delivered: true,
        summary: {
          courier_code: "sicepat",
          courier_name: "SiCepat Express",
          waybill_number: "005036140250",
          service_code: "SIUNT",
          waybill_date: "2023-07-06",
          shipper_name: "DOSS",
          receiver_name: "Samiudin",
          origin: "DKI Jakarta",
          destination: "Pagedangan, Kab. Tangerang",
          status: "DELIVERED",
        },
        details: {
          waybill_number: "005036140250",
          waybill_date: "2023-07-06",
          waybill_time: "02:25:00",
          weight: "1",
          origin: "DKI Jakarta",
          destination: "Pagedangan, Kab. Tangerang",
          shippper_name: "DOSS",
          shipper_address1: "",
          shipper_address2: "",
          shipper_address3: "",
          shipper_city: "DKI Jakarta",
          receiver_name: "Samiudin",
          receiver_address1: "",
          receiver_address2: "",
          receiver_address3: "",
          receiver_city: "Pagedangan, Kab. Tangerang",
        },
        manifest: [
          {
            manifest_code: "IN",
            manifest_description:
              "Paket telah di input (manifested) di Jakarta Selatan [Jaksel Gunung]",
            manifest_date: "2023-07-05",
            manifest_time: "19:25",
            city_name: "",
          },
          {
            manifest_code: "OUT",
            manifest_description:
              "Paket keluar dari Jakarta Selatan [Jaksel Gunung]",
            manifest_date: "2023-07-06",
            manifest_time: "01:04",
            city_name: "",
          },
          {
            manifest_code: "IN",
            manifest_description:
              "Paket telah di terima di Jakarta Timur [Jakarta Sortation 2]",
            manifest_date: "2023-07-06",
            manifest_time: "03:42",
            city_name: "",
          },
          {
            manifest_code: "OUT",
            manifest_description:
              "Paket keluar dari Jakarta Timur [Jakarta Sortation 2]",
            manifest_date: "2023-07-06",
            manifest_time: "03:43",
            city_name: "",
          },
          {
            manifest_code: "IN",
            manifest_description:
              "Paket telah di terima di Tangerang [Tangerang Sortation 1]",
            manifest_date: "2023-07-06",
            manifest_time: "08:28",
            city_name: "",
          },
          {
            manifest_code: "OUT",
            manifest_description:
              "Paket keluar dari Tangerang [Tangerang Sortation 1]",
            manifest_date: "2023-07-06",
            manifest_time: "09:33",
            city_name: "",
          },
          {
            manifest_code: "IN",
            manifest_description:
              "Paket telah di terima di Tangerang [Tangsel Cisauk]",
            manifest_date: "2023-07-06",
            manifest_time: "11:08",
            city_name: "",
          },
          {
            manifest_code: "CRISS CROSS",
            manifest_description:
              "Paket di kembalikan di Tangerang [Tangsel Cisauk] - (CC) Criss Cross",
            manifest_date: "2023-07-06",
            manifest_time: "11:18",
            city_name: "",
          },
          {
            manifest_code: "IN",
            manifest_description:
              "Paket telah di terima di Tangerang Selatan [Tangsel Serpong]",
            manifest_date: "2023-07-07",
            manifest_time: "07:45",
            city_name: "",
          },
          {
            manifest_code: "ANT",
            manifest_description: "Paket dibawa [SIGESIT - ILHAM NUR SAVERO]",
            manifest_date: "2023-07-07",
            manifest_time: "11:24",
            city_name: "",
          },
          {
            manifest_code: "DELIVERED",
            manifest_description:
              "Paket diterima oleh [samiudin - (YBS) Yang Bersangkutan]",
            manifest_date: "2023-07-07",
            manifest_time: "12:29",
            city_name: "",
          },
        ],
        delivery_status: {
          status: "DELIVERED",
          pod_receiver: "samiudin - (YBS) Yang Bersangkutan",
          pod_date: "2023-07-07",
          pod_time: "12:29",
        },
      },
    },
  };

  const handleClose = () => {
    dispatch({
      type: "MODAL_TRACKING",
      payload: false,
    });
  };

  return (
    <Modal
      backdrop="static"
      centered
      show={true}
      onHide={handleClose}
      className="tracking-modal "
      style={{
        overflowY: "initial !important",
      }}
    >
      <div className=" modal-header">
        <div>
          <h5 className="m-0 text-xl">
            {data.data.tracking.summary.courier_name}
          </h5>
          <p className="m-0 text-sm">
            <small>{data.data.tracking.summary.waybill_number}</small>
          </p>
        </div>

        <div>
          <p>{data.data.tracking.summary.status}</p>
        </div>
      </div>
      <div
        className=" modal-header "
        style={{
          border: "none",
        }}
      >
        <div>
          <h6 className="m-0 text-xl">Pengirim</h6>
          <p className="m-0 text-sm">
            <small>{data.data.tracking.summary.shipper_name}</small>
          </p>
          <p>{data.data.tracking.summary.origin}</p>
        </div>

        <div>
          <h6 className="m-0 text-xl">Penerima</h6>
          <p className="m-0 text-sm">
            <small>DOSS</small>
          </p>
          <p>{data.data.tracking.summary.destination}</p>
        </div>
      </div>
      <div
        className="modal-body"
        style={{
          height: "30vh",
          overflowY: "auto",
        }}
      >
        <h6 className="m-0 text-xl mb-4">History</h6>
        <div class="stepper d-flex flex-column  ml-2">
          {data.data.tracking.manifest.map((manifest) => (
            <div class="d-flex mb-1 ">
              <div
                class="d-flex flex-column  align-items-center"
                style={{
                  paddingRight: "1rem",
                }}
              >
                <div class="rounded-circle p-2  stepper-dot text-white mb-1"></div>
                <div class="line h-100"></div>
              </div>
              <div
                style={{
                  marginTop: "-4px",
                }}
              >
                <p className="m-0">
                  <small> {manifest.manifest_date}</small>
                  <small> {manifest.manifest_time} </small>
                </p>
                <p class="text-dark font-bold m-0 pb-3">
                  {manifest.manifest_description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary">
          Ok
        </button>
      </div>
    </Modal>
  );
};

const Test = () => {
  return (
    <div className="w-100">
      <p>asdas</p>

      <ModalTracking></ModalTracking>
    </div>
  );
};

export default Test;
