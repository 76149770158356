import React, {useContext} from "react";
import { Container, Navbar } from "react-bootstrap";
import { IoNotificationsOutline } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";
import { DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Space } from 'antd';
import {AppContext} from "../configs/AppContext";
import {BsPower} from "react-icons/bs";

const NavbarComponent = () => {
  const [state, dispatch] = useContext(AppContext);
  const handleLogout = () => {
    dispatch({
      type: "LOGOUT",
    });
  };

  const items = [
    {
      key: '1',
      danger: true,
      label: 'Logout',
    }
  ];

  const menu = (
    <Menu>
      <Menu.Item onClick={() => handleLogout()} className={"pt-2 px-3"}>
        <BsPower className="me-2" size={25} />
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Navbar fixed="top" className="navbar-top">
      <Container fluid>
        <Navbar.Brand href="/">
          <img
            src="https://main.mobile.doss.co.id/storage/uploads/2022/01/logo-doss-black.webp"
            alt="logo doss"
            className="navbar-logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <div className="hover-icon">
            <IoNotificationsOutline size={25} />
          </div>

          <div className="hover-icon">
            <Dropdown overlay={menu}>
              <AiOutlineUser size={25} />
            </Dropdown>
          </div>


        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
