import React, { useEffect, useContext, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import moment from "moment";
import { AppContext } from "./configs/AppContext";

import { ThemeProvider } from "@mui/material/styles";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./pages/Home";
import Order from "./pages/Order";
import Login from "./pages/Login";
import theme from "./configs/theme";
import ModalLoading from "./components/ModalLoading";
import Test from "./pages/Test";

function App() {
  const [state, dispatch] = useContext(AppContext);

  useEffect(() => {
    if (localStorage.token) {
      dispatch({
        type: "LOGIN",
      });
    } else {
      dispatch({
        type: "LOGOUT",
      });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ModalLoading />
        <Routes>
          <Route exact path="/" element={<Outlet />}>
            <Route path="/login" element={<Login />} />
            <Route path="/test" element={<Test />} />

            <Route element={<PrivateRoute />}>
              <Route path="/" element={<Home />} />
              <Route path="/order" element={<Order />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
