import React, { useContext, useState, useRef } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import Button from "@mui/material/Button";
import { FaRegUser } from "react-icons/fa";
import { BiTimeFive } from "react-icons/bi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BsFillInfoCircleFill, BsPencilSquare } from "react-icons/bs";
import { AiOutlineWhatsApp, AiOutlinePrinter } from "react-icons/ai";
import moment from "moment";
import cleanObject from "../helpers/cleanObject";
import { API, APIUtils } from "../configs/API";
import { AppContext } from "../configs/AppContext";
import { Select } from "antd";
import ReactToPrint from "react-to-print";
import StickerDelivery from "./StickerDelivery";

const CardListContent = ({ cartData, onUpdate, storeList, onTrackClicked }) => {
  const [state, dispatch] = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [showProduct, setShowProduct] = useState(false);
  const [shipmentData, setShipmentData] = useState(null);
  const [formData, setFormData] = useState({
    resi: "",
    store: "",
  });
  const { resi, store } = formData;
  let componentRef = useRef();

  const rejectOrder = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const res = await API.get(`order/reject/${cartData.id}`);

      if (res.data.error == 0) {
        cartData = res.data.data;
      } else {
        alert(res.data.description);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error);
    }
  };

  const acceptOrder = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const data = {
        payment_status: cartData.payment_status,
      };
      const res = await API.post(`order/accept/${cartData.id}`, data);

      if (res.data.error == 0) {
        onUpdate();
        cartData = res.data.data;
      } else {
        alert(res.data.description);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error);
    }
  };

  const deliverOrder = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const payload = cleanObject(formData);
      const res = await API.post(`order/deliver/${cartData.id}`, payload);

      if (res.data.error == 0) {
        cartData = res.data.data;
      } else {
        alert(res.data.description);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error);
    }
  };

  const getShipment = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const res = await API.get(`order/sticker-delivery/${cartData.id}`);

      let stickerContent;
      if (res.data.error == 0) {
        stickerContent = res.data.data;
        setShipmentData(stickerContent);
      } else {
        alert(res.data.description);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error);
    }
  };

  const trackOrder = async (orderId) => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const res = await APIUtils.get(`/tracking/shippment?order_id=${orderId}`);

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });

      if (res.data.error == 0) {
        return onTrackClicked(res.data.data);
      } else {
        alert(res.data.description);
        return null;
      }
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });

      alert(error);
    }
  };

  return (
    <>
      <Modal centered show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <div className="modal-resi-header">Masukan Nomor Resi Pengiriman</div>

          <div className="d-flex justify-content-center">
            <div className="card-list-note mt-4 mb-2">
              <label>Masukan nomor resi</label>
              <input
                type="text"
                placeholder="No. Resi"
                onChange={(e) =>
                  setFormData({ ...formData, resi: e.target.value })
                }
              />
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div className="card-list-note mb-4">
              <label>Dikirim Dari</label>

              <Select
                style={{
                  width: "100%",
                }}
                dropdownStyle={{
                  maxHeight: 400,
                  overflow: "auto",
                  zIndex: "100000",
                }}
                onChange={(e) => setFormData({ ...formData, store: e })}
                onSelect={(e) => setFormData({ ...formData, store: e })}
                options={storeList}
              />
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <Button
              variant="contained"
              className="card-list-accept-btn"
              disableElevation
              onClick={() => deliverOrder()}
              style={{ width: "322px" }}
            >
              Kirim Nomor Resi
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {cartData.shipment && shipmentData && (
        <div style={{ display: "none" }}>
          <StickerDelivery
            key={cartData.id}
            cartData={cartData}
            shipmentData={shipmentData}
            ref={componentRef}
          />
        </div>
      )}

      <div className="card-list-content mb-3">
        <div className="d-flex justify-content-between px-2 py-1 card-list-top">
          <div
            className="d-flex align-items-center"
            style={{ overflowX: "auto", whiteSpace: "nowrap" }}
          >
            <div className="d-flex align-items-center me-2">
              {/*<input id="new-order" type="checkbox" />*/}
              <label
                htmlFor="new-order"
                className="fw-bold"
                style={{ cursor: "pointer" }}
              >
                {cartData.status_text}
              </label>
            </div>

            <div className="divider-vertical">|</div>

            <div className="mx-2 invoice-id-txt">{cartData.order_id}</div>

            <div className="divider-vertical">|</div>

            <div className="mx-2 d-flex align-items-center card-list-cust">
              <FaRegUser className="me-2" />{" "}
              <span>{cartData.user.fullname}</span>
            </div>

            <div className="divider-vertical">|</div>

            <div className="mx-2 d-flex align-items-center card-list-cust">
              <BiTimeFive size={18} className="me-2" />{" "}
              <span>
                {moment(cartData.created_at).format("DD MMMM YYYY, HH:mm")} WIB
              </span>
            </div>
          </div>

          <div
            className="d-flex flex-wrap align-items-center"
            style={{ whiteSpace: "nowrap" }}
          >
            <div className="card-list-limit-txt me-2">Batas Respons</div>
            <div className="card-list-limit-time">
              <BiTimeFive size={18} className="me-1" />
              <span>23 Jam</span>
            </div>
          </div>
        </div>

        <div className="p-3">
          {cartData.status <= 9 && cartData.status != 7 && (
            <div className="card-list-info mb-3">
              Kurir dan layanan pengiriman untuk pesanan ini tidak dapat diubah
            </div>
          )}

          <Row>
            {cartData.cart_item[0] && (
              <Col md={4}>
                <div className="d-flex card-list-product">
                  {cartData.cart_item[0].product_detail.images[0] && (
                    <img
                      src={
                        cartData.cart_item[0].product_detail.images[0] &&
                        cartData.cart_item[0].product_detail.images[0]
                          .small_image
                      }
                      alt=""
                    />
                  )}
                  <div className="ms-3">
                    <div className="card-list-product-title">
                      {cartData.cart_item[0].product_detail.name}
                    </div>
                    <div className="card-list-product-sku">
                      {cartData.cart_item[0].product_detail.code}
                    </div>
                    <div className="card-list-product-price mt-1">
                      {cartData.cart_item[0].quantity} x{" "}
                      {cartData.cart_item[0].item_price.toLocaleString()}
                    </div>
                    {!showProduct && cartData.cart_item.length > 1 && (
                      <div
                        className="d-flex align-items-center card-list-product-more mt-2"
                        onClick={() => setShowProduct(true)}
                      >
                        <span className="me-2">
                          Lihat {cartData.cart_item.length - 1} Produk Lainnya
                        </span>{" "}
                        <IoIosArrowDown />
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            )}

            {cartData.shipment && cartData.shipment_detail && (
              <Col md={4}>
                <div className="card-list-address-header">Alamat</div>
                <div className="card-list-address-content">
                  {cartData.shipment.address.address_name} (
                  {cartData.shipment.address.phone})
                </div>
                <div className="card-list-address-content">
                  {cartData?.shipment_detail.address?.detail ?? ""}, <br />
                  {cartData?.shipment_detail.address?.province ?? ""},{" "}
                  {cartData?.shipment_detail?.address?.city ?? ""}{" "}
                  {cartData?.shipment?.address?.postal_code ?? ""}
                </div>
              </Col>
            )}
          </Row>

          <div className="card-list-info-total mt-3">
            <div className="d-flex align-items-center card-list-total-left">
              <span className="me-1">Total Bayar</span>{" "}
              <BsFillInfoCircleFill size={13} />
            </div>
            <div className="card-list-total-right">
              Rp {cartData.total.toLocaleString()}
            </div>
          </div>
        </div>

        <div className="card-list-bottom px-3 py-2">
          <div className="d-flex align-items-center">
            <a
              href={
                "https://api.whatsapp.com/send/?phone=" + cartData.wa_number
              }
              target="_blank"
              className="d-flex align-items-center card-list-ask-txt me-4"
            >
              <AiOutlineWhatsApp size={20} />{" "}
              <span className="ms-2">Tanya Pembeli</span>
            </a>

            {/*<div className="d-flex align-items-center card-list-ask-txt me-4">*/}
            {/*  <IoDocumentTextOutline size={20} />{" "}*/}
            {/*  <span className="ms-2">Status Pesanan</span>*/}
            {/*</div>*/}

            {cartData.status >= 9 && (
              <div className="d-flex align-items-center card-list-ask-txt me-4">
                <AiOutlinePrinter size={20} />{" "}
                <ReactToPrint
                  trigger={() => <span className="ms-2">Cetak Label</span>}
                  content={() => componentRef.current}
                  onBeforeGetContent={() => getShipment()}
                />
              </div>
            )}

            <div className="d-flex align-items-center card-list-ask-txt">
              <BsPencilSquare size={20} />{" "}
              <div className="card-list-note ms-2">
                <input type="text" placeholder="Ketik catatan toko disini" />
                <div className="max-char">0/60</div>
              </div>
            </div>
          </div>

          {[3, 8].includes(cartData.status) && (
            <div className="d-flex align-items-center">
              <div className="card-list-ask-txt" onClick={() => rejectOrder()}>
                Tolak pesanan
              </div>
              <Button
                variant="contained"
                className="card-list-accept-btn ms-2"
                disableElevation
                onClick={() => acceptOrder()}
              >
                Terima pesanan
              </Button>
            </div>
          )}

          {[9].includes(cartData.status) && (
            <div className="d-flex align-items-center">
              <Button
                variant="contained"
                className="card-list-accept-btn ms-2"
                disableElevation
                onClick={() => setShow(true)}
              >
                Kirim Pesanan
              </Button>
            </div>
          )}

          {cartData.shipmentTracking && [10].includes(cartData.status) && (
            <div className="d-flex align-items-center">
              <Button
                variant="outlined"
                className="card-list-accept-outline-btn ms-2"
                disableElevation
                onClick={() => trackOrder(cartData.order_id)}
              >
                Lacak
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CardListContent;
