import React from "react";
import Chip from "@mui/material/Chip";

const ChipDelivery = ({ loadCart }) => {
  return (
    <div className="d-flex chip-container mb-2 pb-2">
      <Chip
        label="Proses Gudang"
        variant="outlined"
        className="chip-list"
        onClick={() => loadCart('warehouse-process')}
      />

      <Chip
        label="Menunggu Dikirim"
        variant="outlined"
        className="chip-list"
        onClick={() => loadCart('ready-to-send')}
      />
    </div>
  );
};

export default ChipDelivery;
