import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import NavbarComponent from "../components/NavbarComponent";
import MainContent from "../components/MainContent";
import { APP_VERSION } from "../configs/App";

const Home = () => {
  return (
    <Container
      fluid
      className="app-main-container h-100"
      style={{ padding: 0 }}
    >
      <Row style={{ height: "55px" }}>
        <Col>
          <NavbarComponent />
        </Col>
      </Row>

      <Container fluid className="d-flex" style={{ padding: 0 }}>
        <MainContent />
      </Container>
      <div className="small py-2 px-4 " style={{ textAlign: 'right' }}>
        v{APP_VERSION} &#169;{new Date().getFullYear()} PT Global Sukses Digital
      </div>

    </Container>
  );
};

export default Home;
