import React, { useState, useEffect } from "react";
import { InputGroup, FormControl, Row, Col } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import { AiOutlineCalendar } from "react-icons/ai";
import { Cascader, DatePicker } from "antd";

const options = [
  {
    value: "order-type",
    label: "Tipe Pesanan",
    children: [
      {
        value: "pickup-store",
        label: "Ambil di Toko",
      },
      {
        value: "devilery",
        label: "Pengiriman via Kurir",
      },
    ],
  },
  {
    value: "courier",
    label: "Kurir",
    children: [
      {
        value: "sicepat",
        label: "SiCepat",
      },
      {
        value: "lainnya",
        label: "Lainnya",
      },
    ],
  },
  {
    value: "label",
    label: "Label",
    children: [
      {
        value: "belum-dicetak",
        label: "Belum Dicetak",
      },
      {
        value: "sudah-dicetak",
        label: "Sudah Dicetak",
      },
    ],
  },
];

const optionsSort = [
  {
    value: "newest",
    label: "Paling Baru",
  },
  {
    value: "oldest",
    label: "Paling Lama",
  },
];

const cartOptions = [
  {
    value: "o2o",
    label: "O2O",
  },
  {
    value: "web",
    label: "WEB",
  },
];

const FilterComponent = ({ updateFilter }) => {
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [cartType, setCartType] = useState("");
  const { RangePicker } = DatePicker;

  const handleSumbit = () => {
    updateFilter(keyword, filter, sort, dateStart, dateEnd, cartType)
  }

  const handleFilterChange = (v) => {
    setFilter(v)
    updateFilter(keyword, v, sort, dateStart, dateEnd, cartType)
  }

  const handleSortChange = (v) => {
    setSort(v)
    updateFilter(keyword, filter, v, dateStart, dateEnd, cartType)
  }

  const handleCartChange = (v) => {
    setCartType(v)
    updateFilter(keyword, filter, sort, dateStart, dateEnd, v)
  }

  const handleDateChange = (v) => {
    setDateRange(v)
    if (v) {
      setDateStart(v[0].format('YYYY-MM-DD'))
      setDateEnd(v[1].format('YYYY-MM-DD'))
      updateFilter(keyword, filter, sort, v[0].format('YYYY-MM-DD'), v[1].format('YYYY-MM-DD'), cartType)
    } else {
      setDateStart("")
      setDateEnd("")
      updateFilter(keyword, filter, sort, "", "", cartType)
    }
  }

  // Just show the latest item.
  function displayRender(label) {
    return label[label.length - 1];
  }

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        // 👇️ call submit function here
        handleSumbit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [keyword]);

  return (
    <div>
      <Row>
        <Col xs={12} sm={4} md={12} lg={3} className="mb-2">
          <InputGroup className="me-3" style={{ height: "40px", flex: 2 }}>
            <FormControl
              placeholder="Cari berdasarkan kode invoice"
              style={{
                fontSize: "12px",
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
              }}
              value={keyword}
              onChange={e =>
                setKeyword(e.target.value)
              }
            />
            <InputGroup.Text
              style={{
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            >
              <BiSearch size={20} />
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Col xs={12} sm={4} md={3} lg={2} className="mb-2">
          <Cascader
            placeholder="Pilih Filter"
            options={options}
            expandTrigger="hover"
            onChange={(v) => handleFilterChange(v)}
            value={filter}
            className="w-100 cascader-class me-3"
          />
        </Col>
        <Col xs={12} sm={4} md={3} lg={2} className="mb-2">
          <Cascader
            placeholder="Urut Berdasarkan"
            options={optionsSort}
            onChange={(v) => handleSortChange(v)}
            value={sort}
            className="w-100 cascader-class me-3"
          />
        </Col>
        <Col xs={12} sm={4} md={3} lg={2} className="mb-2">
          <Cascader
            placeholder="Pilih Cart"
            options={cartOptions}
            onChange={(v) => handleCartChange(v)}
            value={cartType}
            className="w-100 cascader-class me-3"
          />
        </Col>
        <Col xs={12} sm={4} md={3} lg={3} className="mb-2">
          <RangePicker onChange={(v) => handleDateChange(v)} value={dateRange} className="cascader-class" style={{height: '40px'}} />
        </Col>
      </Row>
    </div>
  );

  // return (
  //   <div className="d-flex">
  //     <InputGroup className="me-3" style={{ height: "40px", flex: 2 }}>
  //       <FormControl
  //         placeholder="Cari berdasarkan kode invoice"
  //         style={{
  //           fontSize: "12px",
  //           borderTopLeftRadius: "8px",
  //           borderBottomLeftRadius: "8px",
  //         }}
  //         value={keyword}
  //         onChange={e =>
  //           setKeyword( e.target.value)
  //         }
  //       />
  //       <InputGroup.Text
  //         style={{
  //           borderTopRightRadius: "8px",
  //           borderBottomRightRadius: "8px",
  //         }}
  //       >
  //         <BiSearch size={20} />
  //       </InputGroup.Text>
  //     </InputGroup>

  //     <Cascader
  //       placeholder="Pilih Filter"
  //       options={options}
  //       expandTrigger="hover"
  //       // displayRender={displayRender}
  //       onChange={(v) => handleFilterChange(v)}
  //       value={filter}
  //       className="cascader-class me-3"
  //     />

  //     <Cascader
  //       placeholder="Urut Berdasarkan"
  //       options={optionsSort}
  //       // displayRender={displayRender}
  //       onChange={(v) => handleSortChange(v)}
  //       value={sort}
  //       className="cascader-class me-3"
  //     />

  //     <Cascader
  //       placeholder="Pilih Cart"
  //       options={cartOptions}
  //       // displayRender={displayRender}
  //       onChange={(v) => handleCartChange(v)}
  //       value={cartType}
  //       className="cascader-class me-3"
  //     />

  //     <RangePicker onChange={(v)=>handleDateChange(v)} value={dateRange} className="cascader-class" />

  //     {/* <div className="rail-select me-3">
  //       <div className="select-side">
  //         <AiOutlineCalendar size={20} color="#0c135f" />
  //       </div>

  //       <FormControl as="select">
  //         <option value="">04 Aug 21 - 02 Nov 21</option>
  //       </FormControl>
  //     </div> */}
  //   </div>
  // );
};

export default FilterComponent;
