import React, { useContext } from "react";
import { Modal, Spinner } from "react-bootstrap";

import { AppContext } from "../configs/AppContext";

const ModalTracking = ({ open = false, data = null, onClose }) => {
  return (
    <Modal
      backdrop="static"
      centered
      show={open}
      onHide={onClose}
      className="tracking-modal "
      style={{
        overflowY: "initial !important",
      }}
    >
      <div className=" modal-header">
        <div>
          <h5 className="m-0 text-xl">
            {data?.tracking?.summary?.courier_name}
          </h5>
          <p className="m-0 text-sm">
            <small>{data?.tracking?.summary?.waybill_number}</small>
          </p>
        </div>

        <div>
          <p>{data?.tracking?.summary?.status}</p>
        </div>
      </div>
      <div
        className=" modal-header "
        style={{
          border: "none",
        }}
      >
        <div>
          <h6 className="m-0 text-xl">Pengirim</h6>
          <p className="m-0 text-sm">
            <small>{data?.tracking?.summary?.shipper_name}</small>
          </p>
          <p>{data?.tracking?.summary?.origin}</p>
        </div>

        <div>
          <h6 className="m-0 text-xl">Penerima</h6>
          <p className="m-0 text-sm">
            <small>{data?.tracking?.summary?.receiver_name}</small>
          </p>
          <p>{data?.tracking?.summary?.destination}</p>
        </div>
      </div>
      <div
        className="modal-body"
        style={{
          height: "30vh",
          overflowY: "auto",
        }}
      >
        <h6 className="m-0 text-xl mb-4">History</h6>
        <div class="stepper d-flex flex-column  ml-2">
          {data?.tracking?.manifest?.map((manifest) => (
            <div class="d-flex mb-1 ">
              <div
                class="d-flex flex-column  align-items-center"
                style={{
                  paddingRight: "1rem",
                }}
              >
                <div class="rounded-circle p-2  stepper-dot text-white mb-1"></div>
                <div class="line h-100"></div>
              </div>
              <div
                style={{
                  marginTop: "-4px",
                }}
              >
                <p className="m-0">
                  <small> {manifest.manifest_date}</small>
                  <small> {manifest.manifest_time} </small>
                </p>
                <p class="text-dark font-bold m-0 pb-3">
                  {manifest.manifest_description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="modal-footer">
        <button onClick={onClose} type="button" className="btn btn-primary">
          Ok
        </button>
      </div>
    </Modal>
  );
};

export default ModalTracking;
