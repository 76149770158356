import axios from "axios";
import moment from "moment";

// Set config defaults when creating the instance || Base URL
export const API = axios.create({
  baseURL: process.env.REACT_APP_HOST + "/api/v1/wh",
});

export const APIUtils = axios.create({
  baseURL: "https://pay.mobile.doss.co.id",
});

API.interceptors.request.use(
  async (req) => {
    if (req.headers === undefined) {
      req.headers = {};
    }

    const token = localStorage.getItem("token");
    // const expired_at = localStorage.getItem("expired_at");
    // const user_info = JSON.parse(localStorage.getItem("userInfo"));

    let accessToken = token;
    // const { id } = user_info;

    // if (moment(new Date()).isSameOrAfter(expired_at)) {
    //   localStorage.clear();
    //   window.location.replace("/login");
    // } else {
    req.headers.Authorization = `Bearer ${accessToken}`;
    // req.headers.common["X-Officer-Id"] = id;
    // }

    return req;
  },
  (error) => {
    Promise.reject(error);
  }
);

API.interceptors.response.use(
  async (response) => {
    if (response.data.error === 401) {
      localStorage.clear();
      return window.location.replace("/login");
    }
    return response;
  },
  (error) => {
    return error;
  }
);
