import React, {useState, useEffect, useContext} from "react";
import { Container } from "react-bootstrap";
import { API } from "../configs/API";
import MainContentCard from "./MainContentCard";
import {AppContext} from "../configs/AppContext";

const MainContent = () => {
  const [state, dispatch] = useContext(AppContext);
  const [dataList, setDataList] = useState();

  const getData = async () => {

    dispatch({
      type: "SET_LOADING",
      payload: true,
    });

    try {
      const res = await API.get(`summary`);
      setDataList(res.data.data);

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      if (error.response.data) {
        alert(error.response.data.message);
      } else {
        alert(error);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container fluid className="mt-4 ms-4">
      <div className="main-title-header">Penting Hari Ini</div>
      <div className="main-title-subheader">
        Aktivitas yang perlu kamu pantau untuk jaga kepuasan pembeli
      </div>

      <div className="d-flex flex-wrap mt-2">
        <MainContentCard name="Pesanan baru" total={dataList ? dataList.new_order:0} route="/order" />
        <MainContentCard name="Siap dikirim" total={dataList ? dataList.ready_to_send:0} route="/order" />
        {/*<MainContentCard name="Pesanan dikomplain" total={0} route="/order" />*/}
        {/*<MainContentCard name="Chat baru" total={0} route="/order" />*/}
        {/*<MainContentCard name="Diskusi baru" total={0} route="/order" />*/}
        {/*<MainContentCard name="Ulasan baru" total={0} route="/order" />*/}
      </div>
    </Container>
  );
};

export default MainContent;
