import React from "react";
//
// const StickerDelivery = (cartData) => {
//   return <input type="text" className="default-input" />;
// };
//

export const StickerDelivery = React.forwardRef((props, ref) => {
  let cartData = props.cartData;
  let shipmentData = props.shipmentData;

  return (
    <div ref={ref}>
      <div className="p-1 bg-white" style={{width: "630px", height: "630px"}}>
        <div className="table-responsive p-4" style={{border: "2px solid black"}}>
          <table className="table table-hover align-td-middle bg-white  mb-0" style={{color: "#333"}}>
            <tr style={{borderBottom: "2px dashed black"}}>
              <td style={{verticalAlign: "unset",padding: "0",paddingLeft: "4px",paddingTop: "4px"}}>
                <img height="37px" src={process.env.REACT_APP_HOST+"/assets/img/sticker/doss-logo.png"} alt="" />
              </td>
              <td style={{padding: "5px 18px 48px 3px"}} className="text-right">
                <h4 style={{fontWeight: "normal"}}>Label Pengiriman</h4>
              </td>
            </tr>
            <tr style={{borderTop: "2px dashed black"}}>
              <td style={{padding: "10px",paddingTop: "20px"}} width="177">
                <img height="60px" src={shipmentData.shipment.courier_data.logo} alt="" id="sticker-courier-logo" />
              </td>
              <td style={{padding: "32px 10px 10px 10px",verticalAlign: "unset"}}>
                <div className="row">
                  <div className="col-6">
                    <span id="sticker-courier-info">{cartData.shipment.courier}</span>
                  </div>
                  <div className="col-6">
                    Order ID
                  </div>
                  <div className="col-6">
                    {cartData.shipment.shipping}
                  </div>
                  <div className="col-6">
                    {cartData.order_id}
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td style={{padding: "10px",paddingBottom: "20px",verticalAlign: "unset"}}>
                <span><b>Kepada</b></span>
              </td>
              <td style={{padding: "10px",paddingBottom: "20px"}}>
                <b>{cartData.user.fullname}</b> <br/>
                <span>{ shipmentData.shipment.address.detail }</span> <br/>
                <span>{ shipmentData.shipment.address.province }, { shipmentData.shipment.address.city }, { shipmentData.shipment.address.postal_code }</span> <br/>
                <span>{ shipmentData.shipment.address.phone }</span> <br/>
              </td>
            </tr>
            <tr>
              <td style={{padding: "10px",verticalAlign: "unset"}}>
                <span><b>Dari</b></span>
              </td>
              <td style={{padding: "10px",paddingBottom: "28px"}}>
                <span id="sticker-store-name"><b>{shipmentData.shipment.store.name}</b></span> <br/>
                <span id="sticker-store-address">{shipmentData.shipment.store.address}</span> <br/>
              </td>

            </tr>
            <tr style={{borderTop: "2px dashed black"}}>
              <td style={{padding: "10px",paddingTop: "18px",paddingLeft: "7px",width: "209px"}} className="text-center">
                <img height="50px" src={process.env.REACT_APP_HOST+"/assets/img/sticker/cs-label.jpg"} alt="" /> <br />
                <p className="m-0" style={{fontSize: "13px"}}>DOSS Customer Service</p> <br />
                <p className="m-0" style={{fontSize: "13px"}}>{shipmentData.shipment.store.mobile}</p>
              </td>
              <td style={{padding: "10px",paddingTop: "33px"}} className="text-center">
                <img src={process.env.REACT_APP_HOST+"/assets/img/sticker/fragile-label.jpg"} alt="" />
                <img src={process.env.REACT_APP_HOST+"/assets/img/sticker/up-label.jpg"} alt="" />
                <img src={process.env.REACT_APP_HOST+"/assets/img/sticker/umbrella-label.jpg"} alt="" />
                <img src={process.env.REACT_APP_HOST+"/assets/img/sticker/care-label.jpg"} alt="" />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
});

export default StickerDelivery;