import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import axios from "axios";

import { AppContext } from "../configs/AppContext";

import DefaultInput from "../components/DefaultInput";
import DefaultInputPassword from "../components/DefaultInputPassword";
import { API } from "../configs/API";
import cleanObject from "../helpers/cleanObject";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { APP_VERSION } from "../configs/App";

const Login = () => {
  const [state, dispatch] = useContext(AppContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [type, setType] = useState("password");

  const { email, password } = formData;

  useEffect(() => {
    if (state.isLogin) {
      navigate('/')
    }
  }, []);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const payload = cleanObject(formData);
      const res = await axios.post(process.env.REACT_APP_HOST + `/api/v1/wh/login`, payload);

      if (res.status == 200) {
        if (res.data.error == 422) {
          alert(JSON.stringify(res.data.errors));
          dispatch({
            type: "SET_LOADING",
            payload: false,
          });
        }
        if (res.data.error === 401) {
          alert(res.data.message);
          dispatch({
            type: "SET_LOADING",
            payload: false,
          });
        }
        if (res.data.error === 0) {
          localStorage.setItem("token", res.data.token)
          dispatch({
            type: "LOGIN",
          });
          navigate("/");
        }
      }

    } catch (error) {
      alert(error);

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    }
  };
  return (
    <div className="w-100">
      <header className="login-header">
        <img
          src="https://doss.co.id/_next/static/images/logo_doss_2-f80e2d723392731fc39da01df1685445.png"
          alt="doss"
        />
      </header>
      <div className="login-content">
        <img
          src="https://main.mobile.doss.co.id/storage/uploads/2023/01/vecteezy-illustration-of-people-trying-to-solve-the-puzzle-of-9734274.webp"
          alt="bg login"
          className="login-bg"
        />

        <section className="login-card-container">
          <div className="login-text-header mb-5">Masuk</div>

          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="default-label">Email</label>
              <input
                type="email"
                className="default-input"
                placeholder="Input Email"
                name="email"
                value={email || ""}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />

              <label className="default-label mt-3">Kata Sandi</label>
              <div className="position-relative">
                {type === "password" ? (
                  <IoEyeOff
                    className="password-icon"
                    size={20}
                    onClick={() => setType("text")}
                  />
                ) : (
                  <IoEye
                    className="password-icon"
                    size={20}
                    onClick={() => setType("password")}
                  />
                )}

                <input type={type} className="default-input"
                  value={password || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                />
              </div>

              {/* <div className="login-forgot-pass mt-3">Lupa kata sandi?</div> */}

              <Button
                type="submit"
                variant="contained"
                className="card-list-accept-btn w-100 h-40 mt-3"
                disableElevation
              >
                Masuk
              </Button>
            </div>
          </form>
        </section>

        <div className="login-copyright">
          v{APP_VERSION} &#169;{new Date().getFullYear()} PT Global Sukses Digital
        </div>
      </div>
    </div>
  );
};

export default Login;
